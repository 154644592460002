<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      v-if="resolutionScreen >= 500"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title
          >{{ $t("trashPage.detaildelete") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list dense tile>
            <v-list-item
              v-for="(item, i) in showdatadelete"
              :key="i"
              v-show="item.backup_status.status === 'ER'"
              dense
              three-line
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.file_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.backup_status.message }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-progress-circular
                  class="ma-0"
                  :rotate="-90"
                  :value="item.value"
                  size="40"
                  color="red"
                  >
                  <div>{{ item.backup_status.value }}</div>
                </v-progress-circular>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="$emit('closedialog')"
            >{{ $t("dragdrop.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      v-else
      persistent
      scrollable
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5 text-center">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-4">
              <span :style="headerPageDialog">{{ $t("trashPage.detaildelete") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-list v-for="(item, i) in showdatadelete" :key="i" v-show="item.backup_status.status === 'ER'">
            <v-list-item dense two-line>
              <v-list-item-avatar>
                <v-progress-circular
                  class="ma-0"
                  :rotate="-90"
                  :value="item.backup_status.value"
                  size="30"
                  color="red"
                >
                  {{ item.backup_status.value }}
                </v-progress-circular>
              </v-list-item-avatar>
              <v-list-item-content class="text-left">
                <v-list-item-title :style="titleText">{{
                  item.file_name
                }}</v-list-item-title>
                <v-list-item-title :style="titleText">{{
                 item.backup_status.message
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="text-center mt-6">
            <v-btn
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="90%"
              color="red"
              outlined
              @click="$emit('closedialog')"
              >{{ $t("changeName.close") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "showdatadelete"],
  data: function() {
    return {
      listdataupload: [],
      fileUpload: [],
      showMenu: false,
      showitem: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
      } else {
      }
    },
  },
  methods: {
  },
  created() {},
};
</script>
<style>
#app_currentrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
  z-index: 9999;
}
</style>
